<template>
    <div class="main">
        <div class="container">
            <div class="trxTitle productListTitle">
                <h1>{{ activeLanguage.addNewProduct }}</h1>
                <router-link
                            to="/trxmodule"
                            class="back-link link-back-button"
                            :title="activeLanguage.return"
                    >
                        <i class="fas fa-arrow-left icon icon"></i>
                        <span class="text-inner">{{ activeLanguage.return }}</span>
                    </router-link>
                <button type="submit" class="btn productListTitleBtn" @click="saveNewProduct">{{ activeLanguage.save }}</button>
            </div>
            <div class="trxTitle">
                <p class="trxProductListTableP">{{ activeLanguage.productEditorIntro }}</p>
            </div>

            <div class="trxMainTables">
                <div class="col-4 trxLeftCols">

                    <div class="trxTable">
                        <div class="trxTableTitle">
                            <p>{{ activeLanguage.productImage }}</p>
                        </div>
                        <div class="trxTableMain">
                            <div class="logoContainer" @dragover.prevent @drop.prevent>
                                    <div class="logoField" @drop="dragFile($event)">
                                        <a
                                            @click.prevent="deleteLogo()"
                                            v-if="!!productImageCode"
                                            href=""
                                            class="deleteLogo hidden-xs"
                                        >
                                            <i class="fas fa-trash" aria-hidden="true"></i>
                                        </a>
                                        <input type="file" @change="uploadLogo($event)" class="customFileInput col-11" />
                                        <div class="logo offset-md-4" style="display: contents;" v-if="!!productImageCode">
                                                    <img :src="productImageCode" class="logoImg" />
                                        </div>
                                        <div class="logoInner" v-else>
                                            <p>{{ activeLanguage.dragAndDrop }}</p>
                                            <p>{{activeLanguage.maxFileSize}} 2MB</p>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>

                    <div class="trxTable">
                        <div class="trxTableTitle">
                            <p>{{ activeLanguage.pricing }}</p>
                        </div>
                        <div class="trxTableMain">
                            <div class="">
                                <label for="price" class="trxInputLabel">{{activeLanguage.unitPrice}}</label>
                                    <div class="input-group">
                                        <div class="input-group-addon trxInputAddon trxAddonIcon">€</div>
                                        <input type="text" v-model="price" class="form-control" id="price" required>
                                    </div>
                            </div>
                            <div style='margin-top: 10px'>
                                <label for="price" class="trxInputLabel">{{activeLanguage.vat}}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon trxInputAddon trxAddonIcon">%</div>
                                        <select name="btw" id="btw" v-model="btw" class="form-control" required>
                                            <option value="0">0</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="9">9</option>
                                            <option value="12">12</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                        </select>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-8 trxRightCols">
                    <div class="trxTable">
                        <div class="trxTableTitle">
                            <p>Product details</p>
                        </div>
                        <div class="trxTableMain">
                            <div class="withSizes">
                                <div class="col-8">
                                    <div>
                                        <label for="name" class='trxInputLabel'>{{activeLanguage.productName}}*</label>
                                        <input type="text" v-model="name" class="form-control" id="name" minlength="3" required>
                                    </div>
                                    <div style="margin-top: 10px">
                                        <label for="name" class='trxInputLabel'>{{activeLanguage.description}}*</label>
                                        <textarea 
                                            name="description" 
                                            id="description" 
                                            cols="20" 
                                            rows="5"
                                            v-model="description"
                                            class="form-control"
                                            style="max-height: 125px"
                                            required
                                            ></textarea>
                                    </div>

                                </div>
                                <div class="col-4">
                                    <div>
                                        <label for="weight" class='trxInputLabel'>{{activeLanguage.weight}}</label>
                                        <div class="input-group">
                                            <div class="input-group-addon trxInputAddon trxWeight">Kg</div>
                                            <input type="text" v-model="weight" class="form-control" id="weight">
                                        </div>
                                    </div>
                                    <div style="margin-top: 10px">
                                        <label for="size" class='trxInputLabel'>{{activeLanguage.size}}</label>
                                        <div class="input-group">
                                            <div class="input-group-addon trxInputAddon trxProductSizes">H</div>
                                            <input type="text" v-model="sizeHeight" class="form-control" id="H"/>
                                        </div>
                                        <div class="input-group" style="margin-top: 10px">
                                            <div class="input-group-addon trxInputAddon trxProductSizes">W</div>
                                            <input type="text" v-model="sizeWidth" class="form-control" id="W"/>
                                        </div>
                                        <div class="input-group" style="margin-top: 10px">
                                            <div class="input-group-addon trxInputAddon trxProductSizes">L</div>
                                            <input type="text" v-model="sizeLength" class="form-control" id="L"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="trxCustomVariables" v-for="(field, index) in custom_fields">
                                <div class="col-6">
                                    <label for="lable" class='trxInputLabel'>Label</label>
                                    <input type="text" v-model="field.label" class="form-control" id="label" minlength="3" required>
                                </div>
                                <div class="col-6">
                                    <label for="value" class='trxInputLabel'>Value</label>
                                    <div style="display: flex;">
                                        <input type="text" v-model="field.name" class="form-control" id="value" minlength="3" required>
                                        <div class="input-group-addon" @click="removeVariable(index)">
                                            <i class="fas fa-trash" aria-hidden="true" style="color: red;"></i>
                                        </div>
                                </div>
                                </div>
                            </div>

                        </div>
                        <div class="trxTableFooter" @click="addCustomFields" v-if="custom_fields.length < 10">
                            {{ activeLanguage.addCustomVariables }}
                        </div>
                    </div>

                    <div class="trxTable" v-for="product in product_variations">
                        <div class="trxTableTitle">
                            <p>{{ activeLanguage.productVariations }}</p>
                        </div>
                        <div class="trxTableMain">
                            <label for="Label" class='trxInputLabel'>Label</label>
                            <input type="text" v-model="product.name" class="form-control" id="Label" minlength="3">

                            <div v-for="(option, index) in product.options">
                                <label for="variation" class='trxInputLabel' style="margin-top: 10px">{{ activeLanguage.variation }}</label>
                                <div style="display: flex;">
                                    <input type="text" v-model="option.value" class="form-control" id="variation" minlength="3">
                                        <div class="input-group-addon" @click="removeVariation(index)" v-show="index !== 0">
                                            <i class="fas fa-trash" aria-hidden="true" style="color: red;"></i>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div class="trxTableFooter" @click="addProductVariations" v-if="showAddProductVariation">
                            {{ activeLanguage.addMoreVariations }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {mapGetters} from  'vuex'

    export default {
        name: 'TrxNewProduct',
        data() {
            return {
                name: null,
                price: null,
                description: null,
                weight: null,
                sizeHeight: null,
                sizeWidth: null,
                sizeLength: null,
                productImageName: null,
                productImageCode: null,
                btw: null,
                label: null,
                custom_fields:[],
                product_variations: [
                    {
                        name: '',
                        options: [
                            {
                                value: '',
                                is_default: false
                            }
                        ]
                    }
                ],
                showAddProductVariation: true
            }
        },
        mounted() {

        },
        methods: {
            dragFile(e) {
                let file = e.dataTransfer.files[0]
                let reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onloadend = () => {
                        this.productImageCode = reader.result;
                    }
                    this.productImageName = e.target.files[0].name
            },
            uploadLogo(e) {
                let file = e.target.files[0]
                let reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onloadend = () => {
                        this.productImageCode = reader.result;
                    }
                    this.productImageName = e.target.files[0].name
            },
            deleteLogo() {
                this.productImageName = null
                this.productImageCode = null
            },
            addProductVariations() {
                if (this.product_variations[0].options.length < 10) {
                    this.product_variations[0].options.push(
                        {
                            value: '',
                            is_default: false
                        }
                    )
                } 
                if(this.product_variations[0].options.length < 10) {
                    this.showAddProductVariation = true
                } else {
                    this.showAddProductVariation = false
                }
                
            },
            addCustomFields() {
                if (this.custom_fields.length < 10) {
                    this.custom_fields.push({
                        label: '',
                        value: ''
                    })
                }
            },
            saveNewProduct() {
                let params = {
                    name: this.name,
                    description: this.description,
                    price: this.price,
                    weight: this.weight,
                    sizeHeight: this.sizeHeight,
                    sizeWidth: this.sizeWidth,
                    sizeLength: this.sizeLength,
                    productImageName: this.productImageName,
                    productImageCode: this.productImageCode ? this.productImageCode.split(',')[1] : null,
                    btw: this.btw,
                    custom_fields: this.custom_fields,
                    product_variations: this.product_variations,
                    label: this.label
                }

                orderAxios
                    .post(`product/add`, params )
                    .then(({ data }) => {
                        EventBus.$emit('showAlertModal', data.message)
                        this.$emit('getProducts')
                        this.name = null
                        this.price = null
                        this.description = null
                        this.weight = null
                        this.sizeHeight = null
                        this.sizeLength = null
                        this.sizeWidth = null
                        this.productImageCode = null
                        this.productImageName = null
                        this.btw = null
                        this.label = null,
                        this.custom_fields = [],
                        this.product_variations = [
                            {
                                name: '',
                                options: [
                                    {
                                        value: '',
                                        is_default: false
                                    }
                                ]
                            }
                        ]
                        this.goToTrxModule()
                    })
                    .catch(error => {
                        console.error(error)
                        EventBus.$emit('showAlertModal', error.response.data.error)
                    })

            },
            goToTrxModule() {
                this.$router.push('/trxmodule')
            },
            removeVariation(index) {
                if(this.product_variations[0].options.length !== 1) {
                    this.product_variations[0].options.splice(index, 1)
                }
                if(this.product_variations[0].options.length < 10) {
                    this.showAddProductVariation = true
                } else {
                    this.showAddProductVariation = false
                }
            },
            removeVariable(index) {
                this.custom_fields.splice(index, 1)
            }

        },
        components: {

        },
        computed: {
            ...mapGetters({
                activeLanguage: 'getTrxLanguage'
            })
        }
    }

</script>

<style>
    .logoTitle {
        display: flex;
        justify-content: center;
    }
    .logoField {
        width: 100%;
        height: 200px;
        max-height: 200px;
        /* border-radius: 10px; */
        /* background-color: #DBDADD; */
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .logoInner {
        margin-top: auto;
        margin-bottom: auto;
    }
    .logoImg {
        max-width: 100%;
        max-height: 200px;
        /* border-radius: 10px; */
        margin: auto;
        width: auto;
        height: auto;
        object-fit: cover;
    }
    .customFileInput {
        /* width: 200px; */
        height: 200px;
        position: absolute;
        opacity: 0;
    }
    .image {
        display: flex;
    }
    .deleteLogo {
        color: #d8271a;
        z-index: 20;
        position: absolute;
        padding: 7px;
        margin-left: 310px;
        margin-top: -15px;
    }
</style>

<style scoped lang="scss">
    p {
        margin-bottom: 0;
    }
    .link-back-button {
        margin-left: auto;
        margin-right: 15px;
        margin-top: 3px;
        display: inline-block;
        width: 40px;
        height: 39px;
        background: #f48d21;
        border-radius: 50px;
        transition: width .3s ease-in-out; 
        .icon {
            font-size: 16px;
            top: 10px;
            left: 12px;
            position: relative;
            margin: 0;
            color: white;
        } 
        .text-inner {
            margin-left: 25px;
            font-size: 17px;
            color: white;
            opacity: 0;
            position: absolute;
            margin-top: 6px;
            z-index: -1;
            transition: opacity .4s ease;
        }
    }
    .link-back-button:hover {
        width: 117px;  
        .text-inner {
            opacity: 1;
            z-index: 1;
        }
    }
</style>